<template>
  <div>
    <v-layout wrap>
      <v-flex>
        <create-tags-modal @addTagSuccess="addTagSuccess" />
      </v-flex>

      <v-flex xs12 sm6 md4 lg3 xl2>
        <v-text-field
          v-model="search"
          hide-details
          solo
          label="Search tag" 
          append-icon="search"
          @input="onSearchUser"
        />
      </v-flex>

      <v-flex xs12 sm6 md4 lg3 xl2>
        <v-select
          v-model="sortBy"
          :items="selectItems"
          item-value="value"
          item-text="name"
          label=""
          solo
          hide-details
          @change="onSelectUsersBy"
        />
      </v-flex>
    </v-layout>

    <v-data-table
      :items="allTagsList"
      :pagination.sync="pagination"
      :loading="isLoading"
      :total-items="total"
      class="elevation-1"
      :rows-per-page-items="[10, 25, 50, 100]"
      :headers-length="2"
    >
      <template slot="items" slot-scope="props">
        <td class="tag-name-td" style="width: 33%">
          <div class="tag-name-wrap">
            <span v-text="props.item.title"></span>
          </div>
        </td>
        <td style="width: 33%">
          <span :class="[props.item.user_id ? 'user' : 'admin', 'tag-label']" v-text="props.item.user_id ? 'user tag' : 'admin tag'"></span>
        </td>
        <td style="text-align: right;width: 33%">
          <div v-if="!props.item.user_id">
            <span v-if="props.item.translations.length < supportedLanguages.length">in queue...</span>
            <v-btn v-if="props.item.translations.length === supportedLanguages.length" icon color="primary" @click="editTag(props.item)">
              <v-icon color="#00c853" small>
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="deleteTag(props.item.id, props.item)">
              <v-icon color="red" small>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
          <div v-else>
            <div v-if="!getIsLoading(props.item.id)">
              <v-btn color="primary" dark @click="convertUserTagIntoAdminTag(props.item)">
                Translate
                <v-icon right color="white" medium>
                  mdi-google-translate
                </v-icon>
              </v-btn>
              <v-btn icon color="primary" @click="deleteTag(props.item.id, props.item)">
                <v-icon color="red" small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
            <span v-show="getIsLoading(props.item.id)">
              <v-progress-circular indeterminate color="amber" />
            </span>
          </div>
        </td>
        <td>
          <div style="display: none" class="alias-wrap">
            <div
              v-for="alias in props.item.alias"
              :key="alias.id"
              class="alias-item mr-3 my-1"
            >
              {{ alias.title }}
              <v-icon
                color="red"
                small
                @click="deleteTagAlias(props.item.id, alias.id, props.item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>

    <div class="text-xs-center pt-2">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        @input="refreshPagination"
      />
    </div>
    <edit-tag-modal
      v-model="editTagModal"
      :edit-tag="editTags"
      @editTagSuccess="editTagSuccess"
    />
  </div>
</template>

<script>
import CreateTagsModal from "@/components/SettingsPage/SettingsTags/CreateTagsModal"
import {
  getAllTagsRequest,
  deleteTagRequest,
  deleteTagAliasRequest,
  convertParentTag
} from "../../../../api/tags"
import { fetchData } from "../../../../utils/response"
import SavesPagination from "../../../../mixins/SavesPaginationMixin"
import EditTagModal from "../../../../components/SettingsPage/SettingsTags/EditTagModal"
import { mapActions, mapGetters } from "vuex"
import Vue from "vue"
export default {
  name: "SettingsTags",
  components: {
    CreateTagsModal,
    EditTagModal
  },
  mixins: [SavesPagination],
  data() {
    return {
      intervalId: null,
      inputSearchTagTimer: null,
      editTags: {},
      editTagModal: false,
      isLoading: false,
      response: null,
      eventHub: new Vue(),
      pagination: {},
      total: 0,
      search: null,
      select: "desc",
      sortBy: {
        name: "Sort by creation date",
        value: null
      },
      order_by: "created_at",
      selectItems: [
        {
          name: "Sort by creation date",
          value: null
        },
        {
          name: "Sort from A to Z",
          value: "asc"
        },
        {
          name: "Sort from Z to A",
          value: "desc"
        }
      ],
      allTagsList: []
    }
  },
  computed: {
      ...mapGetters(['supportedLanguages']),
      ...mapGetters(['tagData']),
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    pagination(pagination) {
      this.$paginationCacheSave(pagination.page, pagination.rowsPerPage)
      this.refresh()
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page

    this.intervalId = setInterval(() => {
      this.refresh()
    }, 15000)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  created() {
    this.fetchLanguages()

    // window.Echo.channel('request-processed')
    //     .listen('ConvertTagDoneEvent', (e) => {
    //       const tag = e.result
    //       let index = this.allTagsList.findIndex(item => item.id === tag.id)
    //       this.$set(this.allTagsList, index, tag)
    //       console.log('ConvertTagDoneEvent', tag)
    //     })
    //     .listen('ConvertTagStartEvent', (e) => {
    //       const tag = e.result
    //       let index = this.allTagsList.findIndex(item => item.id === tag.id)
    //       this.$set(this.allTagsList[index], 'isLoading', true)
    //       console.log('ConvertTagStartEvent', tag)
    //     })
  },
  methods: {
    ...mapActions(["getAllLanguages"]),
    ...mapActions(["getSupportedLanguages"]),
    ...mapActions(["getTag", "convertParentTag"]),
    fetchLanguages() {
      this.$store.dispatch("getAllLanguages")
      this.$store.dispatch('getSupportedLanguages')
    },
    getIsLoading (itemId) {
      const itemIndex = this.allTagsList.findIndex(item => item.id === itemId)
      return itemIndex !== -1 && this.allTagsList[itemIndex].isLoading
    },
    deleteTagAlias(tagId, aliasId, item) {
      const indexTag = this.allTagsList.indexOf(item)
      const isLargeNumber = element => element.id === aliasId
      const index = item.alias.findIndex(isLargeNumber)
      const params = {
        tag_id: tagId,
        alias_id: aliasId
      }
      deleteTagAliasRequest(params)
        .then(() => {
          this.allTagsList[indexTag].alias.splice(index, 1)
        })
        .catch(this.$notificationHandleResponseError)
    },
    deleteTag(id, item) {
      const index = this.allTagsList.indexOf(item)
      deleteTagRequest(id)
        .then(response => {
          this.allTagsList.splice(index, 1)
          this.$notificationShow(response.data[0])
        })
        .catch(this.$notificationHandleResponseError)
    },
    async convertUserTagIntoAdminTag (tag) {
      let index = this.allTagsList.findIndex(item => item.id === tag.id)
      this.$set(this.allTagsList[index], 'isLoading', true)
      let convertedTag = await convertParentTag(tag).catch(error => {
        console.error(error)
      })
      this.$set(this.allTagsList[index], 'isLoading', false)
      this.$set(this.allTagsList, index, convertedTag.data)
    },
    editTagSuccess(data) {
        let index = this.allTagsList.findIndex(item => item.id === this.tagData.id)
        this.$set(this.allTagsList, index, data.item)
    },
    async editTag(item) {
        await this.getTag(item.id)
        this.editTagModal = true
      // this.editTagModal = true
      // const index = this.allTagsList.indexOf(item)
      // let nested_titles = this.aliasToString(item.alias)
      // const languagesIdArr = item.languages.map(item => item.id)
      // this.editTags = {
      //   index: index,
      //   id: item.id,
      //   title: item.title,
      //   nested_titles: nested_titles,
      //   description: item.description || null,
      //   meta_description: item.meta_description || null,
      //   languagesIdArr: languagesIdArr
      // }
    },
    aliasToArray(alias) {
      if (alias && alias.length > 0) {
        let aliasTitle = alias.split(",").map(item => {
          return { title: item }
        })
        return aliasTitle
      } else {
        return null
      }
    },
    aliasToString(alias) {
      if (alias && alias.length > 0) {
        let aliasTag = alias.map(tag => {
          return tag.title
        })
        return aliasTag.join()
      } else {
        return null
      }
    },
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        order_by: this.order_by,
        direction: this.select,
        search: this.search
      }
      this.isLoading = true
      this.allTagsList = await getAllTagsRequest(params)
        .then(response => {
          this.total = response.data.meta.total
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(tag => {
            return tag
          })
        )
        .catch(this.$notificationHandleResponseError)
      this.isLoading = false
    },
    refreshPagination() {
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    onSelectUsersBy() {
      if (!this.sortBy) {
        this.order_by = "created_at"
        this.select = "desc"
      } else {
        this.order_by = "title"
        this.select = this.sortBy
      }
      this.refresh()
    },
    onSearchUser() {
      clearTimeout(this.inputSearchTagTimer)
      this.inputSearchTagTimer = setTimeout(() => {
        this.refresh()
      }, 2000)
    },
    addTagSuccess() {
      this.refresh()
    }
  },
}
</script>

<style>
.tag-name-td {
  width: 320px;
}
.tag-name-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;
}
.alias-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.alias-wrap i:hover,
.tag-name-wrap i:hover {
  cursor: pointer;
  opacity: 0.85;
}
.tag-label {
  padding:3px;
  border-radius:5px;
  font-size:11px;
}
.tag-label.user {
  background-color: #717f94;
}
.tag-label.admin {
  background-color: #696969;
}
</style>
