<template>
  <div>
    <v-btn color="success" class="ma-0" @click="dialog = true">
      Add a tag
    </v-btn>

    <v-dialog v-model="dialog" max-width="500px" @input="v => v || close()">
      <v-card>
        <v-card-title> 
          <v-layout align-center justify-space-between>
            <span class="headline">{{ formTitle }}</span>

            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <div>
              <div class="mb-2 grey--text">
                Name a tag
              </div>

              <v-text-field
                ref="editTitle"
                v-model="title"
                :error-messages="errorTag.title"
                :counter="50"
                :rules="nameRules"
                class="input-solo-box"
                name="title"
                solo
                box
                label=""
                required
                @input="handleTitleInput"
              />
            </div>

            <div class="load-photo-block mb-4">
              <div class="input-image-wrap">
                <div class="mb-2 grey--text">
                  Tag background photo
                </div>
                <v-btn
                  color="info"
                  class="ma-0"
                  @click="$refs.tagImage.click()"
                >
                  Upload Image
                </v-btn>
                <label for="tagImage">
                  <input
                    id="tagImage"
                    ref="tagImage"
                    type="file"
                    accept="image/*"
                    name="tag-image"
                    class="input-hidden"
                    @change="handleImageUpload"
                  >
                </label>
                <span
                  v-if="imageName"
                  class="image-name"
                  v-text="imageName"
                ></span>
                <span v-if="errorTag.image" class="file-error error--text">
                  {{ errorTag.image[0] }}
                </span>
              </div>
              <div class="input-image-wrap">
                <div class="mb-2 grey--text">
                  Tag banner photo
                </div>
                <v-btn
                  color="orange"
                  class="ma-0"
                  @click="$refs.bannerImage.click()"
                >
                  Upload Image
                </v-btn>
                <label for="bannerImage">
                  <input
                    id="bannerImage"
                    ref="bannerImage"
                    type="file"
                    accept="image/*"
                    name="banner-image"
                    class="input-hidden"
                    @change="handleBgImageUpload"
                  >
                </label>
                <span
                  v-if="imageBackgroundName"
                  class="image-name"
                  v-text="imageBackgroundName"
                ></span>
                <span
                  v-if="errorTag.background_image"
                  class="file-error error--text"
                >
                  {{ errorTag.background_image[0] }}
                </span>
              </div>
            </div>

            <div>
              <div class="mb-2 grey--text">
                Add a tag description
              </div>
              <v-textarea
                v-model="description"
                name="description"
                solo
                box
                rows="5"
                :counter="5000"
                :rules="descriptionRules"
              />
            </div>

            <div>
              <div class="mb-2 grey--text">
                Add a META tag description
              </div>
              <v-textarea
                v-model="meta_description"
                name="meta_description"
                solo
                box
                rows="5"
                :counter="5000"
                :rules="descriptionRules"
              />
            </div>
          </v-card-text>

          <v-card-actions class="px-3 pt-0">
            <v-spacer />
            <v-btn color="error" @click.native="close">
              Cancel
            </v-btn>
            <v-btn :loading="isLoading" color="success" @click.native="sendTag">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { addTagRequest } from "../../../api/tags"

export default {
  name: "CreateTagsModal",
  data() {
    return {
      isLoading: false,
      valid: true,
      formTitle: "Create tag",
      dialog: false,
      title: null,
      nested_titles: null,
      description: null,
      meta_description: null,
      image: null,
      imageName: null,
      background_image: null,
      imageBackgroundName: null,
      errorTag: {},
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 50) || "Name must be less than 50 characters"
      ],
      descriptionRules: [
        v =>
          v
            ? v.length <= 5000 ||
              "Description must be less than 5000 characters"
            : false
      ]
    }
  },

  methods: {
    async postTag() {
      this.isLoading = true

      let formData = new FormData()

      this.image ? formData.append("image", this.image) : false

      this.background_image
        ? formData.append("background_image", this.background_image)
        : false

      this.description && this.description.length
        ? formData.append("description", this.description.trim())
        : false

      this.meta_description && this.meta_description.length
      ? formData.append("meta_description", this.meta_description.trim())
      : false

      this.title && this.title.length
        ? formData.append("title", this.title.trim())
        : false

      await addTagRequest(formData)
        .then(() => {
          this.$notificationShow("Tag successfully added to queue...")
          this.$emit("addTagSuccess")
          setTimeout(() => {
            this.close()
          }, 250)
        })
        .catch(error => {
          this.errorTag = error.response.data.errors
          this.$notificationHandleResponseError(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handleTitleInput() {
      if (this.title && this.title.length) {
        if (this.title.length >= 51) {
          this.$refs.editTitle.internalValue = this.title.slice(0, 50)
        }

        const regexpSpace = /  +/g
        const resultSpace = regexpSpace.test(this.title)
        if (resultSpace) {
          this.$refs.editTitle.internalValue = this.title.slice(0, -1)
        }
      }
      this.errorTag.title = null
    },

    validationImageType(image) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.tiff|\.bmp)$/i
      return !!allowedExtensions.exec(image)
    },

    handleImageUpload() {
      this.errorTag.image = null
      this.image = this.$refs.tagImage.files[0]
      this.imageName = this.$refs.tagImage.files[0].name
      if (!this.validationImageType(this.imageName)) {
        this.errorTag.image = {}
        this.errorTag.image[0] =
          "The background image must be a file of type: jpeg, jpg, png, bmp, tiff."
      }
    },

    handleBgImageUpload() {
      this.errorTag.background_image = null
      this.background_image = this.$refs.bannerImage.files[0]
      this.imageBackgroundName = this.$refs.bannerImage.files[0].name
      if (!this.validationImageType(this.imageBackgroundName)) {
        this.errorTag.background_image = {}
        this.errorTag.background_image[0] =
          "The background image must be a file of type: jpeg, jpg, png, bmp, tiff."
      }
    },

    close() {
      this.clearForm()
      this.dialog = false
    },

    async sendTag() {
      if (!this.$refs.form.validate()) {
        return
      }
      if (this.errorTag.background_image || this.errorTag.image) {
        return
      }
      await this.postTag()
    },

    clearForm() {
      this.imageName = null
      this.imageBackgroundName = null
      this.$refs.tagImage.value = ""
      this.$refs.bannerImage.value = ""
      this.image = null
      this.errorTag.background_image = null
      this.errorTag.image = null
      this.background_image = null
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    }
  }
}
</script>

<style>
.input-solo-box.v-text-field--box.v-text-field--single-line input {
  margin-top: 0;
}
.load-photo-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.input-hidden {
  opacity: 0;
  position: absolute;
  z-index: -1;
  visibility: hidden;
  width: 0;
}
.input-image-wrap:last-of-type {
  text-align: right;
}
.input-image-wrap {
  flex: 0 0 50%;
  max-width: 49%;
  position: relative;
}
.input-image-wrap label {
  display: block;
}
.file-error {
  font-size: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  line-height: 14px;
  display: inline-block;
  margin-top: 3px;
  color: red !important;
  font-weight: bold;
}
.image-name {
  font-size: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  line-height: 14px;
  display: inline-block;
  margin-top: 3px;
  color: #9e9e9e !important;
  font-weight: bold;
  max-height: 28px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
}
</style>
