<template>
  <v-dialog :value="show" fullscreen hide-overlay @input="closeModal()">
    <v-card>
      <v-container>
        <!-- Modal title -->
        <v-card-title>
          <v-layout align-center justify-space-between>
            <span class="headline">{{ formTitle }}</span>
            <v-btn icon dark class="ma-0" @click.native="closeModal">
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>

        <!-- Language buttons -->
        <v-btn v-for="language in supportedLanguages" :key="language.id" round small color="primary" dark :class="[{'active': language.id === selectedLanguage}, 'language-button']" @click="selectLanguage(language.id)">
          {{ language.language }}
        </v-btn>

        <!-- Open form -->
        <v-form ref="form" v-model="valid">
          <div style="display: flex">
            <v-card-text class="tag-main">
              <div>
                <div class="mb-2 grey--text">
                  Name a tag
                </div>

                <v-text-field
                  ref="editTitle"
                  v-model="tagData.title"
                  :error-messages="errorTag.title"
                  :counter="50"
                  :rules="nameRules"
                  class="input-solo-box"
                  name="title"
                  solo
                  box
                  label=""
                  required
                  @input="handleTitleInput"
                />
              </div>

              <div class="load-photo-block mb-4">
                <div class="input-image-wrap">
                  <div class="mb-2 grey--text">
                    Tag background photo
                  </div>
                  <v-btn color="info" class="ma-0" @click="$refs.tagImage.click()">
                    Upload Image
                  </v-btn>
                  <label for="tagImageEdit">
                    <input
                      id="tagImageEdit"
                      ref="tagImage"
                      type="file"
                      accept="image/*"
                      name="tag-image"
                      class="input-hidden"
                      @change="handleImageUpload"
                    >
                  </label>
                  <span
                    v-if="imageName"
                    class="image-name"
                    v-text="imageName"
                  ></span>
                  <span v-if="errorTag.image" class="file-error error--text">
                    {{ errorTag.image[0] }}
                  </span>
                </div>
                <div class="input-image-wrap">
                  <div class="mb-2 grey--text">
                    Tag banner photo
                  </div>
                  <v-btn
                    color="orange"
                    class="ma-0"
                    @click="$refs.bannerImage.click()"
                  >
                    Upload Image
                  </v-btn>
                  <label for="bannerImageEdit">
                    <input
                      id="bannerImageEdit"
                      ref="bannerImage"
                      type="file"
                      accept="image/*"
                      name="banner-image"
                      class="input-hidden"
                      @change="handleBgImageUpload"
                    >
                  </label>
                  <span
                    v-if="imageBackgroundName"
                    class="image-name"
                    v-text="imageBackgroundName"
                  ></span>
                  <span
                    v-if="errorTag.background_image"
                    class="file-error error--text"
                  >
                    {{ errorTag.background_image[0] }}
                  </span>
                </div>
              </div>

              <div class="descriptions-wrapper">
                <div v-for="language in supportedLanguages" v-show="language.id === selectedLanguage" :key="language.id">
                  <div v-if="tagData.translations">
                    <h3>{{ language.language }}</h3>
                    <!-- Description -->
                    <div>
                      <div class="mb-2 grey--text">
                        Add a tag description
                      </div>
                      <v-textarea
                        :id="`description-${language.id}`"
                        :ref="`description-${language.id}`"
                        v-model="tagData.translations[findIndexByLanguageId(language.id)].description"
                        :name="`description-${language.id}`"
                        counter="5000"
                        maxlength="5000"
                        solo
                        box
                        rows="5"
                        @input="descriptionChange"
                      />
                    </div>

                    <!-- Meta description -->
                    <div>
                      <div class="mb-2 grey--text">
                        Add a META tag description
                      </div>
                      <v-textarea
                        :id="`meta_description-${language.id}`"
                        :ref="`meta_description-${language.id}`"
                        v-model="tagData.translations[findIndexByLanguageId(language.id)].meta_description"
                        :name="`meta_description-${language.id}`"
                        counter="5000"
                        maxlength="5000"
                        solo
                        box
                        rows="5"
                        @input="descriptionChange"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div v-for="translation in tagData.translations" :key="translation.id">
                  <v-layout>
                    <span class="translate-language">{{ translation.language.code }}</span>
                    <v-flex xs12 md4 style="margin-right:20px">
                      <v-text-field v-model="translation.title" :rules="nameRules" :counter="50" label="Title" required />
                    </v-flex>
                    <v-flex xs12 md4>
                      <v-text-field v-model="translation.slug" :rules="nameRules" :counter="50" label="Slug" required />
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-card-text>
          </div>
          <v-card-actions class="px-3 pt-3">
            <v-spacer />
            <v-btn color="error" @click.native="closeModal">
              Cancel
            </v-btn>
            <v-btn :loading="isLoading" color="success" @click.native="sendTag">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { editTagRequest } from "../../../api/tags"
import { mapGetters } from "vuex"
export default {
    name: "EditTagModal",
    props: {
        value: Boolean,
        editTag: {
            type: [Object],
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            isLoading: false,
            valid: true,
            formTitle: "Edit tag",
            title: null,
            nested_titles: null,
            description: null,
            meta_description: null,
            image: null,
            imageName: null,
            background_image: null,
            imageBackgroundName: null,
            errorTag: {},

            // work with language switcher
            selectedLanguage: null,
            inputs: [],
            translations: [],

            nameRules: [
                v => !!v || "Name is required",
                v => (v && v.length <= 50) || "Name must be less than 50 characters",
                v => (v && v.trim().length > 0) || "Name can't be empty"
            ]
        }
    },

    computed: {
        ...mapGetters(['supportedLanguages']),
        ...mapGetters(['tagData']),

        show: {
            get() {
                if (this.value) {
                    this.selectLanguage(this.supportedLanguages.find(item => {
                        return item.code === 'en'
                    }).id)
                }
                return this.value
            },
            set(value) {
                this.$emit("input", value)
            }
        }
    },

  methods: {
        findIndexByLanguageId(languageId) {
          return this.tagData.translations.findIndex(item => item.language.id === languageId)
        },

        selectLanguage(languageId) {
            this.selectedLanguage = languageId
        },

        descriptionChange() {
            this.descriptionErrors = null
        },

        closeModal() {
            this.clearForm()
            this.show = false
        },

        async postTag() {
            this.isLoading = true
            let formData = new FormData()

            this.image ? formData.append("image", this.image) : false

            this.background_image
                ? formData.append("background_image", this.background_image)
                : false

            this.tagData.title && this.tagData.title.length
                ? formData.append("title", this.tagData.title.trim())
                : false

            this.tagData.translations.forEach((translation, key) => {
                formData.append(`description[${translation.language.id}]`, this.tagData.translations[key].description || '')
                formData.append(`meta_description[${translation.language.id}]`, this.tagData.translations[key].meta_description || '')
            })

            this.tagData.translations.forEach((translation, key) => {
                formData.append(`tagTranslatedTitle[${translation.language.id}]`, this.tagData.translations[key].title)
                formData.append(`tagSlug[${translation.language.id}]`, this.tagData.translations[key].slug)
            })

            await editTagRequest(this.tagData.id, formData)
                .then(response => {
                    this.$notificationShow("Tag successfully updated")
                    this.$emit("editTagSuccess", { item: response.data })
                    setTimeout(() => {
                        this.closeModal()
                    }, 250)
                })
                .catch(error => {
                    this.errorTag = error.response.data.errors
                    this.$notificationHandleResponseError(error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        handleTitleInput() {
            if (this.editTag.title && this.editTag.title.length) {
                if (this.editTag.title.length >= 51) {
                    this.$refs.editTitle.internalValue = this.title.slice(0, 50)
                }

                const regexpSpace = /  +/g
                const resultSpace = regexpSpace.test(this.editTag.title)
                if (resultSpace) {
                    this.$refs.editTitle.internalValue = this.editTag.title.slice(0, -1)
                }
            }
            this.errorTag.title = null
        },

        validationImageType(image) {
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.tiff|\.bmp)$/i
            return !!allowedExtensions.exec(image)
        },

        handleImageUpload() {
            this.errorTag.image = null
            this.image = this.$refs.tagImage.files[0]
            this.imageName = this.$refs.tagImage.files[0].name
            if (!this.validationImageType(this.imageName)) {
                this.errorTag.image = {}
                this.errorTag.image[0] =
                    "The background image must be a file of type: jpeg, jpg, png, bmp, tiff."
            }
        },

        handleBgImageUpload() {
            this.errorTag.background_image = null
            this.background_image = this.$refs.bannerImage.files[0]
            this.imageBackgroundName = this.$refs.bannerImage.files[0].name
            if (!this.validationImageType(this.imageBackgroundName)) {
                this.errorTag.background_image = {}
                this.errorTag.background_image[0] =
                    "The background image must be a file of type: jpeg, jpg, png, bmp, tiff."
            }
        },

        async sendTag() {
            if (!this.$refs.form.validate()) {
                return
            }
            if (this.errorTag.background_image || this.errorTag.image) {
                return
            }
            await this.postTag()
        },

        clearForm() {
            this.imageName = null
            this.imageBackgroundName = null
            this.$refs.tagImage.value = ""
            this.$refs.bannerImage.value = ""
            this.image = null
            this.errorTag.background_image = null
            this.errorTag.image = null
            this.background_image = null
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
        }
    }
}
</script>

<style>
.input-solo-box.v-text-field--box.v-text-field--single-line input {
    margin-top: 0;
}
.load-photo-block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.input-hidden {
    opacity: 0;
    position: absolute;
    z-index: -1;
    visibility: hidden;
    width: 0;
}
.input-image-wrap:last-of-type {
    text-align: right;
}
.input-image-wrap {
    flex: 0 0 50%;
    max-width: 49%;
    position: relative;
}
.input-image-wrap label {
    display: block;
}
.file-error {
    font-size: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    line-height: 14px;
    display: inline-block;
    margin-top: 3px;
    color: red !important;
    font-weight: bold;
}
.image-name {
    font-size: 12px;
    word-break: break-word;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    line-height: 14px;
    display: inline-block;
    margin-top: 3px;
    color: #9e9e9e !important;
    font-weight: bold;
    max-height: 28px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
}

.descriptions-wrapper {
    background-color:#525252;
    padding:10px;
    border-radius:10px;
    margin-bottom:20px;
}

.language-button.active {
    background-color:#858500!important;
}

.translate-language {
    background-color:#607d8b;
    align-self:center;
    border-radius:10px;
    padding:5px;
    width:30px;
    text-align:center;
    margin-right:5px;
}
</style>
